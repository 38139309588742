<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-3">
        <b class="d-block pb-2"><img src="assets/images/logo-white.svg" class="fot-logo" alt="Radius Recycling" /></b>
        <p>222 SW Columbia Street, Suite 1150<br>
          Portland, OR<br>
          <a [routerLink]="'/company/contacts'">Contact Us</a>
        </p>
        <div class="follow-us">
          <a href="https://www.linkedin.com/company/radius-recycling/" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a>
          <a href="https://www.youtube.com/@radiusrecycling" target="_blank" class="youtube"><i class="fa-brands fa-youtube"></i></a>
        </div>
      </div>
      <div class="col-md-8 col-lg-9">
        <div class="row">
          <div class="col-md-6 col-lg-3 my-3 my-md-0">
            <h6 class="font-anton"><a [routerLink]="'/company'">About</a></h6>
            <ul>
              <li><a [routerLink]="'/company'">Company</a></li>
              <li><a [routerLink]="'/company/about-radius-recycling/management'">Leadership</a></li>
              <li><a [routerLink]="'/company/about-radius-recycling/history'">History</a></li>
              <li><a [routerLink]="'/company/about-radius-recycling/culture'">Culture</a></li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-3 my-3 my-md-0">
            <h6 class="font-anton"><a [routerLink]="'/company/investors'">Investors</a></h6>
            <ul>
              <li><a [routerLink]="'/company/investors/stock-information'">Stock Information</a></li>
              <li><a [routerLink]="'/company/investors/news-releases'">News</a></li>
              <li><a [routerLink]="'/company/investors'" fragment="investorMaterials">Investor Materials</a></li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-3 my-3 my-md-0">
            <h6 class="font-anton"><a [routerLink]="'/company/sustainability'">Sustainability</a></h6>
            <ul>
              <li><a href="assets/documents/radius-recycling-2024-sustainability-report.pdf" target="_blank">2024 Sustainability Report</a></li>
              <li><a href="assets/documents/radius-recycling-esg-database-2024.pdf" target="_blank">ESG Data Hub</a></li>
              <li><a [routerLink]="'/company/sustainability'" fragment="Reports">Past Reports</a></li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-3 my-3 my-md-0">
            <h6 class="font-anton"><a [routerLink]="'/company/careers'">Careers</a></h6>
            <ul>
              <li><a [routerLink]="'/company/careers'">Overview</a></li>
              <li><a href="https://recruiting2.ultipro.com/SCH1011SSTL/JobBoard/02ac23c5-914c-4ab4-9851-dc1bc7bd9c94/?q=&o=postedDateDesc&w=&wc=&we=&wpst=)" target="_blank">Join Our Team</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row footer-text">
      <div class="copyright col-md-6">&copy;{{copyDate | date: 'yyyy'}} Radius Recycling, Inc.</div>
      <div class="copyright col-md-6">
        <ul class="footer-nav inline-nav">
          <li>&nbsp;<a [routerLink]="'/sitemap'">Site Map</a></li>
          <li>&nbsp;<a [routerLink]="'/company/about-radius-recycling/ethics'">Ethics</a></li>
          <li>&nbsp;<a [routerLink]="'/legal-notices'">Terms</a></li>
          <li class="is-last">&nbsp;<a [routerLink]="['/legal-notices']" fragment="privacy">Privacy</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
